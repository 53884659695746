<template>
  <div class="copyWriting">
    <!-- 草稿箱 -->
    <div class="tab-item">
      <a-form-model
        ref="searchForm"
        :model="searchForm"
        layout="horizontal"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-row type="flex" justify="space-between">
          <a-col :span="5">
            <a-form-model-item label="品牌">
              <a-select
                mode="multiple"
                v-model="searchForm.principalIds"
                option-filter-prop="children"
                show-search
                placeholder="请选择品牌"
                :maxTagCount="1"
                allowClear
                @change="handleSearchPrincipalChange"
              >
                <a-select-option v-for="item in principalList" :key="item.value" :value="item.value">{{
                  item.label
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="5">
            <a-form-model-item label="车系">
              <a-select
                mode="multiple"
                :loading="seriesLoading"
                v-model="searchForm.seriesId"
                placeholder="请选择车系"
                show-search
                option-filter-prop="children"
                :maxTagCount="1"
                allowClear
                @change="onSearch"
              >
                <a-select-option v-for="item in seriesList" :key="item.id">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="5">
            <a-form-model-item label="选题">
              <TopicTreeInput
                show-search
                style="width: 100%"
                v-model="searchForm.topicSelectionTagIdList"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :replaceFields="{ title: 'name' }"
                placeholder="选题"
                allow-clear
                multiple
                tree-default-expand-all
                :maxTagCount="3"
                @change="onSelectChange"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="5">
            <a-form-model-item label="创建人">
              <a-select
                v-model="searchForm.creator"
                :disabled="limited"
                placeholder="请选择添加人"
                show-search
                option-filter-prop="children"
                allowClear
                @change="onSearch"
              >
                <a-select-option v-for="item in creatorList" :key="item.username" :value="item.username">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row type="flex" justify="space-between">
          <a-col>
            <a-col :span="12">
              <a-form-model-item label="创建时间">
                <a-range-picker
                  v-model="pickerTime"
                  style="width: 100%"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  allowClear
                  @change="onSearch"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="名称">
                <a-input-search
                  style="width: 200px"
                  v-model="searchForm.name"
                  placeholder="请输入草稿名称"
                  allowClear
                  @search="onSearch"
                />
              </a-form-model-item>
            </a-col>
          </a-col>
          <a-col>
            <a-space size="large">
              <a-button shape="circle" type="link" icon="sync" @click="getDataList()"></a-button>
              <a-button style="width: 100px" @click="handleAdd"><a-icon type="plus" />草稿</a-button>
            </a-space>
          </a-col>
        </a-row>
      </a-form-model>

      <a-table
        :loading="tableLoading"
        :columns="columns"
        :data-source="dataList"
        :pagination="false"
        row-key="id"
        :scroll="{ x: 1500 }"
        @change="onTableChange"
      >
        <div class="nameLine" slot="draftTitle" slot-scope="text, record">
          <span v-show="!record.showInput" class="name_title">{{ record.name || '' }}</span>
          <a-icon v-show="!record.showInput" class="editIcon" type="edit" @click="handleEditName(record)" />
          <a-input v-show="record.showInput" type="text" style="width: 60%" v-model.trim="tempName" />
          <a-button
            :loading="record.btnLoading"
            v-show="record.showInput"
            type="link"
            size="small"
            @click="handleEditConfirm(record)"
            >确定</a-button
          >
          <a-button
            :loading="record.btnLoading"
            v-show="record.showInput"
            type="link"
            size="small"
            @click="handleEditCancel(record)"
            >取消</a-button
          >
        </div>
        <div slot="writtingPreview" slot-scope="text, record">
          <div class="writtingPreview">
            <div class="previewLeft" @click="openCheckWritting(record)">
              <img
                v-if="record.previewUrl"
                style="height: 100%; width: 100%; object-fit: cover"
                :src="record.previewUrl"
                referrerpolicy="no-referrer"
              />
              <div v-else style="height: 100%; width: 100%; background-color: #cccccc29"></div>
            </div>
            <div class="previewRight">
              <div class="rightTitle" @click="openCheckWritting(record)">
                <a-popover>
                  <template slot="content">
                    <p>{{ record.noteTitle ? record.noteTitle : '' }}</p>
                  </template>
                  {{ record.noteTitle ? record.noteTitle : '-' }}
                </a-popover>
              </div>
              <template v-if="record.topicSelectionTagList && record.topicSelectionTagList.length > 0">
                <div>
                  <a-tag :color="record.topicSelectionTagList[0] ? record.topicSelectionTagList[0].colorHex : 'blue'">{{
                    record.topicSelectionTagList[0] ? record.topicSelectionTagList[0].tagName : '-'
                  }}</a-tag>
                  <a-tag v-if="record.topicSelectionTagList.length > 1" color="blue"
                    >+{{ record.topicSelectionTagList.length - 1 }}</a-tag
                  >
                </div>
              </template>
              <div class="rightXhs" v-show="record.nickname" style="display: flex">
                <div class="logo">
                  <img src="@/assets/icon/xhs_logo.png" alt="" />
                </div>
                <div class="xhsId">{{ record.nickname || '-' }}</div>
              </div>
            </div>
          </div>
        </div>
        <div slot="carInfo" slot-scope="text, record">
          <div>{{ record.principalName || '-' }}</div>
          <div>{{ record.carSeriesName || '-' }}</div>
        </div>
        <!-- <div slot="writtingTheme" slot-scope="text, record">
          <div class="themeList" v-show="record.topicSelectionTagList.length">
            <div
              v-for="(val, index) in record.topicSelectionTagList"
              :key="index"
              class="themeItem"
              :style="`background-color: ${val.colorHex || '#adafb3'}`"
            >
              {{ val.tagName }}
            </div>
          </div>
          <div v-show="!record.topicSelectionTagList.length">-</div>
        </div> -->
        <div slot="operation" slot-scope="text, record">
          <a-space>
            <a-button type="link" @click="handleEdit(record)">编辑</a-button>
            <a v-clipboard:copy="record.sharePublishLink" v-clipboard:success="onCopy" v-clipboard:error="onCopyError"
              >复制链接</a
            >
            <a-dropdown>
              <a class="ant-dropdown-link" @click="(e) => e.preventDefault()"> 更多 <a-icon type="down" /> </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a
                    v-clipboard:copy="record.id"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onCopyError"
                    style="color: #1890ff; text-align: center"
                    >复制ID</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm
                    placement="topRight"
                    title="删除文案不影响已发布的笔记，确定删除？"
                    ok-text="删除"
                    cancel-text="取消"
                    @confirm="handleDel(record.id)"
                  >
                    <a-button type="link" style="color: red">删除</a-button>
                  </a-popconfirm>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-space>
          <!-- <a-button type="link" style="margin: 0 10px" @click="handlePublish(record)">发布</a-button> -->
        </div>
      </a-table>
      <base-pagination
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
        :options="['30', '50']"
      />
    </div>

    <!-- 添加弹窗 -->
    <a-modal v-model="showAddModal" title="添加笔记" @cancel="handleAddCancel">
      <a-form-model
        style="margin-top: 30px"
        ref="addForm"
        :model="addFormState"
        :rules="addRules"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-form-model-item label="品牌" prop="principalId">
          <a-select
            v-model="addFormState.principalId"
            placeholder="请选择品牌"
            show-search
            option-filter-prop="children"
            @change="handleAddPrincipalChange"
            :maxTagCount="3"
            allowClear
          >
            <a-select-option v-for="item in principalList" :key="item.value" :value="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="车系" prop="vehicleSeriesId">
          <a-select
            :loading="seriesLoading"
            v-model="addFormState.vehicleSeriesId"
            placeholder="请选择车系"
            show-search
            option-filter-prop="children"
            allowClear
          >
            <a-select-option v-for="item in addSeriesList" :key="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button key="back" @click="handleAddCancel"> 取消 </a-button>
        <a-button key="submit" type="primary" @click="handleAddOk"> 下一步 </a-button>
      </template>
    </a-modal>

    <!-- 发布弹窗 -->
    <PublishComp
      v-if="showPublishModal"
      :copyWritingContentId="copyWritingContentId"
      @closePublish="showPublishModal = false"
      @publishSucc="publishSucc"
    />

    <!-- 查看/编辑抽屉 -->
    <writtingDrawer
      v-if="showDrawer"
      :readOnly="readOnly"
      @closeDrawer="handleDrawerClose"
      :draftId="draftId"
      @openDrafEdit="readOnly = false"
      @updateList="updateList"
      :drawerEditData="drawerEditData"
      :isLocalData="isLocalData"
    />
  </div>
</template>

<script>
import PublishComp from './components/PublishComp';
import SellingTab from '../aigc/sellingPoints';
import api from '@/api/xhsAgencyApi';
import moment from 'moment';
import TopicTreeInput from '@/components/xhsAgencyTool/TopicTreeInput';
import writtingDrawer from '@/components/xhsAgencyTool/writtingDrawer.vue';
import MessageTextCompVue from '@/components/MessageTextComp.vue';
import { trackRequest } from '@/utils/track';

export default {
  components: { PublishComp, SellingTab, writtingDrawer, TopicTreeInput, MessageTextCompVue },
  data() {
    const columns = [
      {
        title: '名称',
        align: 'center',
        width: 280,
        scopedSlots: { customRender: 'draftTitle' },
      },
      {
        title: '草稿',
        width: 290,
        scopedSlots: { customRender: 'writtingPreview' },
      },
      {
        title: '车系',
        width: 120,
        scopedSlots: { customRender: 'carInfo' },
      },
      {
        title: '创建时间',
        sorter: true,
        align: 'center',
        dataIndex: 'ctime',
        customRender: (text) => {
          return text || '-';
        },
      },
      {
        title: '创建人',
        align: 'center',
        dataIndex: 'creator',
        customRender: (text) => {
          return text || '-';
        },
      },
      {
        title: '操作时间',
        align: 'center',
        dataIndex: 'atime',
        customRender: (text) => {
          return text || '-';
        },
      },
      {
        title: '操作人',
        align: 'center',
        dataIndex: 'actor',
        customRender: (text) => {
          return text || '-';
        },
      },
      {
        align: 'center',
        title: '操作',
        width: 235,
        fixed: 'right',
        scopedSlots: { customRender: 'operation' },
      },
    ];
    const addRules = {
      principalId: [{ required: true, message: '品牌不能为空', trigger: 'change' }],
      vehicleSeriesId: [{ required: true, message: '车系不能为空', trigger: 'change' }],
    };
    const defaultImg = require('@/assets/images/img-default.png');

    return {
      defaultImg,
      // userName: this.$router.app.user.username,
      currentTab: 0,
      columns,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      searchForm: {
        seriesId: undefined,
        name: '',
        creator: '',
        ctime: [],
        topicSelectionTagIdList: [],
      },
      seriesLoading: false,
      principalList: [],
      seriesList: [],
      dataList: [],
      tableLoading: false,
      pagination: {
        total: 1,
        current: 1,
        pageSize: 30,
      },
      showAddModal: false,
      addSeriesList: [],
      addFormState: {
        principalId: undefined,
        vehicleSeriesId: undefined,
      },
      addRules,
      showPublishModal: false,
      copyWritingContentId: '',
      creatorList: [],
      showDrawer: false,
      draftId: '',
      readOnly: false,
      drawerEditData: {},
      isLocalData: false,
      pickerTime: [],
      tempName: '',
      limited: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const { limited } = await this.checkLimit();
      this.limited = limited;
      this.searchForm.creator = this.$router.app.user.username;
      this.getCreatorList();
      this.fetchPrincipalList();
      this.getDataList();
    },
    getCreatorList() {
      this.handleRequest('getCopywrittingCreatorList', (data) => {
        this.creatorList = data;
      });
    },
    getDataList() {
      const startTime = this.pickerTime[0] ? `${moment(this.pickerTime[0]).format('YYYY-MM-DD')} 00:00:00` : undefined;
      const endTime = this.pickerTime[1] ? `${moment(this.pickerTime[1]).format('YYYY-MM-DD')} 23:59:59` : undefined;
      const params = {
        principalIdList: this.searchForm.principalIds,
        carSeriesIdList: this.searchForm.seriesId,
        creator: this.searchForm.creator,
        name: this.searchForm.name,
        page: this.pagination.current,
        size: this.pagination.pageSize,
        startTime,
        endTime,
        isDesc: this.searchForm.isDesc,
        topicSelectionTagIdList: this.searchForm.topicSelectionTagIdList,
      };
      this.tableLoading = true;
      this.handleRequest(
        'getCopyWrittingList',
        (data) => {
          this.dataList = data.list.map((val) => {
            let previewUrl = '';
            if (val.materialArr?.length > 0 && val.materialArr[0].previewUrl) {
              if (
                val.materialArr[0].previewUrl.indexOf('snapshot') == -1 &&
                (val.materialArr[0].previewUrl.indexOf('content') > -1 ||
                  val.materialArr[0].previewUrl.indexOf('afanti') > -1)
              ) {
                if (val.materialArr[0].previewUrl.indexOf('x-oss-process') > -1) {
                  previewUrl = val.materialArr[0].previewUrl += `/resize,l_120,m_lfit`;
                } else {
                  previewUrl = val.materialArr[0].previewUrl += `?x-oss-process=image/resize,l_120,m_lfit`;
                }
              } else {
                previewUrl = val.materialArr[0].previewUrl;
              }
            }
            val.showInput = false;
            val.btnLoading = false;
            return { ...val, previewUrl };
          });
          console.log(this.dataList);
          this.pagination.total = data.total;
        },
        params
      ).finally(() => (this.tableLoading = false));
    },
    onSearch() {
      this.pagination.current = 1;
      this.getDataList();
    },
    onReset() {
      const isDesc = this.searchForm.isDesc;
      this.searchForm = this.$options.data().searchForm;
      this.searchForm.isDesc = isDesc;
      this.searchForm.creator = this.$router.app.user.username;
      this.seriesList = [];
      this.pickerTime = [];
      this.onSearch();
    },
    async fetchPrincipalList() {
      api.getBrandList().then((res) => {
        this.principalList = res.data.map((item) => {
          return { label: item.principalName, value: item.id };
        });
      });
    },
    async handleSearchPrincipalChange(id) {
      this.searchForm.seriesId = undefined;
      if (!id.length) {
        this.seriesList = [];
        this.onSearch();
        return;
      }
      const principalIds =
        id.reduce((prev, item) => {
          prev += `,${item}`;
          return prev;
        }) || '';
      principalIds && (await this.getSeriesList(principalIds, 'seriesList'));
      this.onSearch();
    },
    handleAdd() {
      const { href } = this.$router.resolve({
        name: 'articleDetail',
      });
      window.open(href, '_blank');
    },
    async handleAddPrincipalChange(id) {
      this.addFormState.vehicleSeriesId = undefined;
      let principalIds = id ? id : '';
      if (!id) this.addSeriesList = [];
      principalIds && (await this.getSeriesList(principalIds, 'addSeriesList'));
    },
    getSeriesList(principalIds, typeList) {
      this[typeList] = [];
      if (!principalIds) return;
      this.seriesLoading = true;
      api
        .getNewTypeList({ principalIds })
        .then((res) => {
          this[typeList] = res.data.map((val) => ({ id: val.id, name: val.name }));
        })
        .finally(() => (this.seriesLoading = false));
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    handleAddCancel() {
      this.$refs.addForm.clearValidate();
      this.addSeriesList = [];
      this.addFormState = {
        principalId: undefined,
        vehicleSeriesId: undefined,
      };
      this.showAddModal = false;
    },
    handleAddOk() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.showAddModal = false;
          const params = {
            principalId: this.addFormState.principalId,
            principalName: this.principalList.find((val) => val.value === this.addFormState.principalId).label,
            vehicleSeriesId: this.addFormState.vehicleSeriesId,
            vehicleSeriesName: this.addSeriesList.find((val) => val.id === this.addFormState.vehicleSeriesId).name,
          };
          const { href } = this.$router.resolve({
            name: 'copywritingDetail',
            query: params,
          });
          window.open(href, '_blank');
          this.handleAddCancel();
        } else {
          return false;
        }
      });
    },
    handleDel(id) {
      this.handleRequest(
        'handleCopywrittingDel',
        () => {
          this.$message.success('操作成功');
          if (this.dataList.length == 1 && this.pagination.current > 1) this.pagination.current--;
          this.getDataList();
          this.handleTrack('delete_draft', {
            draft_id: id,
          });
        },
        id
      );
    },
    handleEdit(value) {
      let routeData = this.$router.resolve({
        name: 'articleDetail',
        query: { draftId: value.id },
      });
      window.open(routeData.href, '_blank');

      this.handleTrack('edit_draft', {
        draft_id: value.id,
      });
    },
    async handlePublish(value) {
      const res = await this.checkHavePic(value.id);
      if (!res) return;
      this.copyWritingContentId = value.id;
      this.showPublishModal = true;
    },
    async checkHavePic(id) {
      const hide = this.$message.loading('查询中...', 0);
      const { code, data, message } = await api.getCopywrittingDetail(id).finally(() => hide());
      if (code === 200) {
        if (!data.materialArr || !data.materialArr.length) {
          this.$message.error('需补充素材图片');
          return false;
        }
        if (!data.noteTitle || !data.noteTitle.trim().length) {
          this.$message.error('需补充素材标题');
          return false;
        }
        if (!data.noteDetail || !data.noteDetail.trim().length) {
          this.$message.error('需补充素材正文');
          return false;
        }
        return true;
      } else {
        this.$message.error(`${message}`);
        return false;
      }
    },
    handleArticleJump(link) {
      if (!link) return this.$message.error(`链接有误`);
      window.open(link, '_blank');
    },
    publishSucc() {
      const link = this.$router.resolve({
        name: 'xhsNoteAnalysis',
      });
      const messageComp = this.$createElement(MessageTextCompVue, {
        props: { message: '发布成功，查看笔记数据可前往', pageName: '笔记分析页', link: link.href },
      });
      this.$message.success({
        content: messageComp,
        key: 'note',
        duration: 3,
      });
      this.showPublishModal = false;
      this.onSearch();
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    makeAiStory() {
      const { href } = this.$router.resolve({
        name: 'makeAiStory',
      });
      window.open(href, '_blank');
    },
    onCopy() {
      this.$message.success('复制成功');
    },
    onCopyError() {
      this.$message.error('复制失败，该浏览器不支持自动复制');
    },
    onTableChange(pagination, filters, sorter) {
      const { order, field } = sorter;
      this.searchForm.isDesc = !order ? true : order == 'descend' ? true : false;
      this.getDataList();
    },
    handleEditName(value) {
      let hasEdit = false;
      this.dataList.forEach((val) => {
        if (val.showInput) hasEdit = true;
      });
      if (hasEdit) {
        this.$message.info('当前有未关闭输入');
      } else {
        this.tempName = value.name;
        value.showInput = true;
      }
    },
    async handleEditConfirm(value) {
      if (!this.tempName) return this.$message.info('名称不得为空');
      value.btnLoading = true;
      await this.handleRequest(
        'handleSaveCopywriting',
        () => {
          this.getDataList();
          this.$message.success('修改成功');
        },
        {
          id: value.id,
          name: this.tempName,
        }
      ).finally(() => {
        value.btnLoading = false;
        this.handleEditCancel(value);
      });
    },
    handleEditCancel(value) {
      value.showInput = false;
      this.tempName = '';
    },
    onSelectChange(topicSelectionTagIdList) {
      this.searchForm.topicSelectionTagIdList = topicSelectionTagIdList;
      this.onSearch();
    },
    // 小红书主页跳转
    // async handleJumpXHS(value) {
    //   const hide = this.$message.loading('查询中...', 0);
    //   await this.handleRequest(
    //     'getXhsAccountPage',
    //     (data) => {
    //       window.open(data, '_blank');
    //     },
    //     {
    //       authorId: value.authorId,
    //     }
    //   ).finally(() => hide());
    // },
    // ---------------------------------抽屉相关
    openCheckWritting(value) {
      this.readOnly = true;
      this.showDrawer = true;
      this.draftId = value.id;
    },
    handleDrawerClose() {
      this.showDrawer = false;
      this.drawerEditData = {};
      this.isLocalData = false;
      this.draftId = undefined;
    },
    updateList() {
      this.getDataList();
      this.handleDrawerClose();
    },
    handleTrack(trackEvent, trackParams = {}) {
      const to = this.$route;
      trackRequest(to, trackEvent, trackParams);
    },
    checkLimit() {
      return new Promise((resolve) => {
        let limited = false;
        const limitedRoleList = ['猛士运营'];
        let limitedName = '';
        this.$router.app.user.roles.forEach((v) => {
          if (limitedRoleList.indexOf(v.name) > -1) {
            limited = true;
            limitedName = v.name;
            return;
          }
        });
        resolve({ limited, limitedName });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.copyWriting {
  margin: 8px;
}
.media-column {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 10px;
  text-align: left;
  background-color: #00152917;
  border-radius: 8px;
  cursor: pointer;

  .media-left {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .media-right {
    flex: 1;

    .right-txt {
      display: flex;
      align-items: center;
      justify-content: start;

      .logo {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.writting-title {
  max-width: 250px;
  background-color: #f2f2f2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/deep/ .ant-tabs-bar {
  margin-bottom: 0 !important;
}

.tab-item {
  padding: 20px;
  background-color: #fff;
}

/deep/ .ant-tabs-tab {
  width: 200px;
  text-align: center;
  font-weight: bold;
}

.nameLine {
  .name_title {
    display: inline-block;
    width: 195px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .editIcon {
    opacity: 0;
    color: rgb(126, 126, 241);
    cursor: pointer;
  }

  &:hover {
    .editIcon {
      opacity: 1;
    }
  }
}

.writtingPreview {
  display: flex;

  .previewLeft {
    flex-shrink: 0;
    margin-right: 5px;
    width: 60px;
    height: 80px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }

  .previewRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .rightTitle {
      width: 180px;
      color: #000;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    .rightXhs {
      display: flex;
      justify-content: start;
      align-items: center;

      .logo {
        width: 20px;
        height: 20px;
        overflow: hidden;
        border-radius: 10px;
        margin: 0;
        margin-right: 5px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .xhsId {
        flex: 1;
        max-width: 145px;
        color: #7f7f7f;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }
    }
  }
}

.blue-text {
  color: #1890ff !important;
  cursor: pointer;
}

.themeList {
  display: flex;
  flex-wrap: wrap;

  .themeItem {
    margin: 2px 2px 0 0;
    padding: 0 5px;
    border-radius: 5px;
    color: #fff;
  }
}
</style>
